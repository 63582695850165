import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StyledWork from "../styles/StyledWork"
import StyeldSection from "../styles/StyledWork"
import scrollTo from "gatsby-plugin-smoothscroll"
import StyledButton from "../styles/StyledButton"

const MyWork = () => {
  const data = useStaticQuery(graphql`
    query {
      tistange: file(relativePath: { eq: "tistange.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      tims: file(relativePath: { eq: "timsuniverse.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      todo: file(relativePath: { eq: "todoApp.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      recipe: file(relativePath: { eq: "recipeApp.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      crud: file(relativePath: { eq: "crudApp.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      calculator: file(relativePath: { eq: "calculatorApp.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="My Work" />
      <h1 className="expand" style={{ textAlign: "center" }}>
        My Work
      </h1>
      <h2>Websites</h2>
      <StyledWork>
        <figure>
          <Img fluid={data.tistange.childImageSharp.fluid} />
        </figure>
        <h3>
          <a
            href="https://tistange.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            tistange.com
          </a>
        </h3>
        <hr />

        <figure>
          <Img fluid={data.tims.childImageSharp.fluid} />
        </figure>
        <h3>
          <a
            href="https://timsuniverse.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            timsuniverse.com
          </a>
        </h3>
        <hr />
      </StyledWork>

      <h2 style={{ textAlign: "center" }}>React Projects</h2>
      <StyeldSection>
        <h3>ToDoApp with Authentication and Authorization</h3>
        <figure>
          <Img
            fluid={data.todo.childImageSharp.fluid}
            alt=" screen shot of todo app"
          />
        </figure>
        <h4>Technology</h4>
        React, Styled Components, Material UI, React Router, NoSQL Database and
        Serverless Functions, Firebase: storage, database, authentication cloud
        functions and Hosting.
        <h4> Objective</h4>
        <p>
          The aim of this project was to create an app to help users keep track
          of key daily activities with authorization and authentication features
          alongside other useful attributes. Drawing inspiration from experience
          on a previous project; which my co-developer and I developed an
          application that facilitated the creation of accounts for new buyers,
          allowing the buyers to upload information for cross location
          evaluation of potential clients, I decided to create the todo app to
          capture some of the tools utilized in the completion of that project.
        </p>
        <ul>
          <li>
            <a
              href="https://to-do-app-76997.firebaseapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Demo
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Tonye123/todoList-firebase"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Repo
            </a>
          </li>
        </ul>
        <hr />
        <h3>MyRecipeApp</h3>
        <figure>
          <Img
            fluid={data.recipe.childImageSharp.fluid}
            alt=" screen shot of recipe app"
          />
        </figure>
        <h4>Technology</h4>
        HTML, Reactjs, React Router, Styled Components, REST Api , Localstorage,
        Sessions
        <h4> Objective</h4>
        <p>
          The aim of this project was to create a search recipe app, where
          interested people can search for recipes and save their favourites as
          they explore or dabble daily into the world of cooking and recipe
          creation and use.
        </p>
        <ul>
          <li>
            <a
              href="https://recipe-app-tonye.netlify.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Demo
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Tonye123/recipeApp"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Repo
            </a>
          </li>
        </ul>
        <hr />
        <h3>Crud Book App</h3>
        <figure>
          <Img
            fluid={data.crud.childImageSharp.fluid}
            alt="screen shot of book app"
          />
        </figure>
        <h4>Technology</h4>
        HTML,Reactjs,Styled Components, materialUI
        <h4>Objective</h4>
        <p>
          This project was first conceptualized to support my (continuous)
          reading habit but evolved to incorporate some critical
          development/design competences. Eventually the project was designed to
          show the CRUD process (create, read, update and delete) with a book
          app whilst addressing the need to track my reading progress. The focus
          was to add books read so far, in the current year (2020), to keep
          track of how much reading has been done and note milestones achieved
          based on set objectives for the year. By developing this app; I
          intended to give the user (or any user, including myself) the ability
          to create, edit/update, access and delete each entry whilst
          maintaining a referable note on reading progress throughout the year.
        </p>
        <ul>
          <li>
            <a
              href="https://crud-bookapp.netlify.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Demo
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Tonye123/CRUD-Book-App"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Repo
            </a>
          </li>
        </ul>
        <hr />
        <h3>Calculator App</h3>
        <figure>
          <Img
            fluid={data.calculator.childImageSharp.fluid}
            alt="screen shot of calcultor app"
          />
        </figure>
        <h4>Technology</h4>
        HTML,reactjs,Styled Components
        <h4>Objective</h4>
        <p>
          The aim of this project was to create a basic calculator in React. I
          focused on developing a basic app which I could improve upon and build
          on; following tests by various users.
        </p>
        <ul>
          <li>
            <a
              href="https://calculatorapptonye.netlify.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Demo
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Tonye123/CalculatorApp"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Repo
            </a>
          </li>
        </ul>
        <StyledButton onClick={() => scrollTo("#top")}>
          Back to top
        </StyledButton>
      </StyeldSection>
    </Layout>
  )
}
export default MyWork
