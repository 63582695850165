
import styled from 'styled-components'




const StyeldSection = styled.section`
    a {
        color: #BC5D2E;
    }

  

    h4 {
        color: darkred;
        margin-bottom: 0.3rem;
        margin-top: 0.7rem;
        font-weight: 400;
    }

    li {
        list-Style: none;
    }


`


export default StyeldSection